import Home from "./components/Home";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import {BrowserRouter as Router} from "react-router-dom";


function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Home />
      <Footer />
      </Router>
    </>
  );
}

export default App;
