import React from "react";
export default function Footer() {
  return (
    <>
      <section
        className="ptb-50 bg-pattern contact-content"
        data-scroll-index="4"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="content-contact">
                <h6 className="text-white">Let‘s Work Together</h6>
                <div className="row">
                  <div className="col-lg-5 col-md-7">
                    <h5 className="contact-text">
                      <a
                        href="mailto:web.ajayrawat@gmail.com"
                        className="text-hover"
                        alt="webajayrawat"
                      >
                        web.ajayrawat@gmail.com
                      </a>
                    </h5>
                  </div>
                  <div className="col-lg-4 col-md-5 text-center">
                    <h5 className="contact-text">
                      <a href="tel:9953978335" className="text-hover" alt="webajayrawat">
                        +91 99539 78335
                      </a>
                    </h5>
                  </div>
                  <div className="col-lg-3 col-md-12 col-12">
                    <div className="social">
                      <a
                        href="https://www.linkedin.com/in/webajayrawat/"
                        alt="webajayrawat"
                        target="_blank"
                        rel="noopener"
                        className="icon linkedin"
                        title="Linkedin"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                      <a
                        href="https://www.behance.net/ajayrawat"
                        alt="webajayrawat"
                        target="_blank"
                        rel="noopener"
                        className="icon behance"
                        title="Behance"
                      >
                        <i className="fab fa-behance"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/webajayrawat/"
                        alt="webajayrawat"
                        target="_blank"
                        rel="noopener"
                        className="icon instagram"
                        title="Instagram"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a
                        href="https://api.whatsapp.com/send?phone=+919953978335&amp;text=Hi Ajay"
                        alt="webajayrawat"
                        target="_blank"
                        rel="noopener"
                        className="icon whatsapp-icon"
                        title="Whatsapp Now"
                      >
                        <i class="fab fa-whatsapp"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="text-center bg-black ptb-30 ">
        <div className="container">
          <p>&copy; 2021 webajayrawat. <span>All Rights Reserved.</span></p>
        </div>
      </footer>
    </>
  );
}
