import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
const vizecare = "../assets/img/work/vizecare.jpg";
const techiedom = "../assets/img/work/techiedom.jpg";
const emotionally = "../assets/img/work/emotionally.jpg";
const dezinecareers = "../assets/img/work/dezinecareers.jpg";
const healingtouch = "../assets/img/work/healingtouch.jpg";
const housecarbike = "../assets/img/work/housecarbike.jpg";
const ieltsgurgaon = "../assets/img/work/ieltsgurgaon.jpg";
const infutive = "../assets/img/work/infutive.jpg";
const jantaxray = "../assets/img/work/jantaxray.jpg";
const luxecarwash = "../assets/img/work/luxecarwash.jpg";
const mannhit = "../assets/img/work/mannhit.jpg";
const rentoclick = "../assets/img/work/rentoclick.jpg";
const spacecreattors = "../assets/img/work/spacecreattors.jpg";
const star = "../assets/img/work/star.jpg";
const staredu = "../assets/img/work/staredu.jpg";
const fosterbright = "../assets/img/work/fosterbright.jpg";
const resume = "../assets/pdf/Ajay-Rawat(Resume).pdf";
const drnareshbansal = "../assets/img/work/drnareshbansal.jpg";
const akshayaorganics = "../assets/img/work/akshayaorganics.jpg";

const options = {
  loop: true,
  margin: 60,
  mouseDrag: true,
  autoplay: true,
  smartSpeed: 500,
  dots: true,
  nav: true,
  navText: [
    '<i class="pe-7s-angle-left"></i>',
    '<i class="pe-7s-angle-right"></i>',
  ],
  responsiveClass: true,
  responsive: {
    0: {
      items: 1,
      nav: true,
    },
    480: {
      items: 1,
      nav: false,
    },
    768: {
      items: 2,
      nav: false,
    },
    1200: {
      items: 2,
      nav: true,
      loop: false,
    },
  },
};
export default function Home() {
  return (
    <>
      <section className="banner bg-pattern" data-scroll-index="0">
        <div className="inner">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <picture>
                    <source
                      media="(min-width: 1200px)"
                      srcset="../assets/img/profile/ajay-rawat.jpg"
                    />
                    <source
                      media="(min-width: 768px)"
                      srcset="../assets/img/profile/ajay-rawat-small.jpg"
                    />
                    <img
                      src="../assets/img/profile/ajay-rawat-small.jpg"
                      alt="Ajay Rawat Web Designer"
                      className="profile-logo"
                    />
                  </picture>
                </div>
                <div className="col-md-9">
                  <div className="summary">
                    <h1 className="text-head">Hi, I'm Ajay Rawat 👋</h1>
                    <p className="text-giant">
                      I'm a Web Designer with 4+ years of experience.
                      Proficient in HTML 5, CSS 3, Sass, Bootstrap, JavaScript,
                      jquery, WordPress, Figma, Adobe Illustrator, Adobe
                      Photoshop, React(Basic) and I am eager to learn new things
                      in web designing.
                    </p>
                      
                    <div className="btn-banner">
                      <a className="butn btn-outline" href={resume} download>
                        <span>
                          Download CV <i class="fas fa-file-alt"></i>
                        </span>
                      </a>
                      <Link
                        className="butn btn-second btn-bg"
                        to="/"
                        data-scroll-nav="2"
                      >
                        <span>View Projects</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-content ptb-50" data-scroll-index="1">
        <div className="inner">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="content-head">Work Experience</h3>
              <div className="content-item">
                <h5>Webpulse Solution Pvt. Ltd.</h5>
                <p className="head-grey">
                  UI/UX Designer <i>• 2022 - Present</i>
                </p>
                <ul className="list-style">
                  <li>Designed websites that implemented an SEO-optimized design.</li>
                  <li>
                  Designed websites that are easy to access and increase the user experience.
                  </li>
                  <li> 
                  Designed the website's visual imagery and ensure it was in line with company branding policy or the requirements of the clients.

                  </li>
                  <li>
                  Creating logos, Flex banners and Website banners.

                  </li>
                </ul>
              </div>
              <div className="content-item">
                <h5>Techiedom</h5>
                <p className="head-grey">
                  Web Designer <i>• 2021</i>
                </p>
                <ul className="list-style">
                  <li>
                    Planning, design and implementation of complete websites.
                  </li>
                  <li>
                    Responsibilities included designing website layout using
                    HTML, CSS, and Illustrator.
                  </li>
                  <li>
                    Creating logos, banners and App screens for Techiedom.
                  </li>
                </ul>
              </div>
              <div className="content-item">
                <h5>InFutive Technology Pvt. Ltd.</h5>
                <p className="head-grey">
                  Web Designer <i>• 2018 – 21</i>
                </p>
                <ul className="list-style">
                  <li>
                    Lead UI/UX website designer for a fast-paced design firm.
                    created an average of four minor websites and 2 major
                    websites per month.
                  </li>
                  <li>
                    Created custom graphic sets for clients, with 85% client
                    satisfaction scores.
                  </li>
                  <li>
                    Handled all composition, colour, illustration, typography
                    and branding for client projects. Turned in projects an
                    average of 5% faster than targets.
                  </li>
                  <li>
                     Meet clients to identify their needs and liaise regularly with them 
                  </li>
  
                </ul>
              </div>

              <h3 className="content-head space-head">Education</h3>
              <div className="content-item">
                <ul className="list-style">
                  <li>
                    10th Pass from C.B.S.E Board Delhi (2013) Sr. Shri Ram Model
                    Public School
                  </li>
                  <li>
                    12th Pass from C.B.S.E Board Delhi (2015) KL Mehta Dayanand
                    Public School
                  </li>
                  <li>
                    Diploma in Web Designing from TGC Animation & Multimedia
                    (2017)
                  </li>
                  <li>
                    B.COM (Pass) from School of Open Learning, University of
                    Delhi (2021)
                  </li>
                </ul>
              </div>
              <div className="content-item">
                <h3 className="content-head space-head">Skills</h3>
                <ul className="list-tags">
                  <li>HTML 5</li>
                  <li>CSS 3</li>
                  <li>Sass</li>
                  <li>Bootstrap</li>
                  <li>UI/UX</li>
                  <li>Figma</li>
                  <li>JavaScript</li>
                  <li>jquery</li>
                  <li>WordPress</li>
                  <li>Wireframing</li>
                  <li>Adobe Illustrator</li>
                  <li>Adobe Photoshop</li>
                  <li>Page Speed Optimisation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ptb-50 bg-pattern" data-scroll-index="2">
        <div className="container">
          <div className="row">
            <div className="section-head mb-0 text-center col-sm-12">
              <h4 className="text-white">Web Projects</h4>
            </div>

            <div className="col-md-12">
              <OwlCarousel className="owl-theme portfolio" {...options}>
                <div className="item">
                  <picture>
                    <source
                      media="(min-width: 1200px)"
                      srcset={akshayaorganics}
                    />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/akshayaorganics-small.jpg"
                    />
                    <img
                      src="../assets/img/work/akshayaorganics-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://akshayaorganics.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
                <div className="item">
                  <picture>
                    <source media="(min-width: 1200px)" srcset={fosterbright} />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/fosterbright-small.jpg"
                    />
                    <img
                      src="../assets/img/work/fosterbright-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>

                  <a
                    className="item-link"
                    href="https://www.fosterbright.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
                <div className="item">
                  <picture>
                    <source media="(min-width: 1200px)" srcset={rentoclick} />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/rentoclick-small.jpg"
                    />
                    <img
                      src="../assets/img/work/rentoclick-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://www.rentoclick.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
                <div className="item">
                  <picture>
                    <source media="(min-width: 1200px)" srcset={healingtouch} />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/healingtouch-small.jpg"
                    />
                    <img
                      src="../assets/img/work/healingtouch-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://thehealingtoucheyecentre.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
                <div className="item">
                  <picture>
                    <source media="(min-width: 1200px)" srcset={techiedom} />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/techiedom-small.jpg"
                    />
                    <img
                      src="../assets/img/work/techiedom-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://www.techiedom.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
                <div className="item">
                  <picture>
                    <source media="(min-width: 1200px)" srcset={emotionally} />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/emotionally-small.jpg"
                    />
                    <img
                      src="../assets/img/work/emotionally-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://www.emotionally.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
                <div className="item">
                  <picture>
                    <source media="(min-width: 1200px)" srcset={infutive} />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/infutive-small.jpg"
                    />
                    <img
                      src="../assets/img/work/infutive-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://www.infutive.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
                <div className="item">
                  <picture>
                    <source
                      media="(min-width: 1200px)"
                      srcset={drnareshbansal}
                    />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/drnareshbansal-small.jpg"
                    />
                    <img
                      src="../assets/img/work/drnareshbansal-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="http://www.drnbansal.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
                <div className="item">
                  <picture>
                    <source media="(min-width: 1200px)" srcset={vizecare} />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/vizecare-small.jpg"
                    />
                    <img
                      src="../assets/img/work/vizecare-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="http://www.vizecare.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>

                <div className="item">
                  <picture>
                    <source media="(min-width: 1200px)" srcset={jantaxray} />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/jantaxray-small.jpg"
                    />
                    <img
                      src="../assets/img/work/jantaxray-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://www.jantaxray.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>

                <div className="item">
                  <picture>
                    <source media="(min-width: 1200px)" srcset={star} />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/star-small.jpg"
                    />
                    <img
                      src="../assets/img/work/star-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://www.starimaging.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
                <div className="item">
                  <picture>
                    <source
                      media="(min-width: 1200px)"
                      srcset={spacecreattors}
                    />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/spacecreattors-small.jpg"
                    />
                    <img
                      src="../assets/img/work/spacecreattors-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://www.spacecreattors.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
                <div className="item">
                  <picture>
                    <source media="(min-width: 1200px)" srcset={luxecarwash} />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/luxecarwash-small.jpg"
                    />
                    <img
                      src="../assets/img/work/luxecarwash-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://www.theluxecarwash.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
                <div className="item">
                  <picture>
                    <source media="(min-width: 1200px)" srcset={staredu} />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/staredu-small.jpg"
                    />
                    <img
                      src="../assets/img/work/staredu-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://www.staredu.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>

                <div className="item">
                  <picture>
                    <source media="(min-width: 1200px)" srcset={mannhit} />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/mannhit-small.jpg"
                    />
                    <img
                      src="../assets/img/work/mannhit-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://www.mannhitvacations.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
                <div className="item">
                  <picture>
                    <source media="(min-width: 1200px)" srcset={ieltsgurgaon} />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/ieltsgurgaon-small.jpg"
                    />
                    <img
                      src="../assets/img/work/ieltsgurgaon-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://www.ieltsgurgaon.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
                <div className="item">
                  <picture>
                    <source media="(min-width: 1200px)" srcset={housecarbike} />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/housecarbike-small.jpg"
                    />
                    <img
                      src="../assets/img/work/housecarbike-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://www.housecarbike.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
                <div className="item">
                  <picture>
                    <source
                      media="(min-width: 1200px)"
                      srcset={dezinecareers}
                    />
                    <source
                      media="(min-width: 1030px)"
                      srcset="../assets/img/profile/dezinecareers-small.jpg"
                    />
                    <img
                      src="../assets/img/work/dezinecareers-small.jpg"
                      alt="Ajay Rawat Web Designer"
                    />
                  </picture>
                  <a
                    className="item-link"
                    href="https://www.dezinecareers.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Ajay Rawat Web Designer"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <section className="works section-padding  bg-grey" data-scroll-index="3">
        <div className="container">
          <div className="row">
            <div className="section-head text-center col-sm-12">
              <h4>Graphic Projects</h4>
            </div>

            <div className="filtering text-center mb-20 col-sm-12">
              <div className="filter">
                <span data-filter="*" className="active">
                  All
                </span>
                <span data-filter=".branding">Branding</span>
                <span data-filter=".logos">Logo</span>
                <span data-filter=".creatives">Creatives</span>
                <span data-filter=".app-ui">App UI</span>
              </div>
            </div>

            <div className="gallery full-width">
              <div className="row">
                <div className="col-lg-4 col-md-6 items branding">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/branding/thumb-techiedom.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/branding/thumb-techiedom-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/branding/thumb-techiedom-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>
                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/branding/techiedom.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>Techiedom | Branding</p>
                        <h6>Visiting card</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 items creatives">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/creatives/thumb-img-1.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/creatives/thumb-img-1-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/creatives/thumb-img-1-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>
                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/creatives/img-1.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>Techiedom | Creatives</p>
                        <h6>Social Media Post</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 items branding">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/branding/thumb-qualitesoft.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/branding/thumb-qualitesoft-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/branding/thumb-qualitesoft-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>

                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/branding/qualitesoft.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>Qualitesoft | Branding</p>
                        <h6>Visiting Card</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 items app-ui">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/apps/thumb-bimadukaan.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/apps/thumb-bimadukaan-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/apps/thumb-bimadukaan-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>

                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/apps/bimadukaan.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>Bimadukaan | App UI</p>
                        <h6>App Design</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 items app-ui">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/apps/thumb-socialhouse.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/apps/thumb-socialhouse-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/apps/thumb-socialhouse-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>

                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/apps/socialhouse.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>Socialhouse | App UI</p>
                        <h6>App Design</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 items branding">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/branding/thumb-rodidust.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/branding/thumb-rodidust-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/branding/thumb-rodidust-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>

                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/branding/rodidust.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>Rodidust | Branding</p>
                        <h6>Visiting Card</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 items logos">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/logo/thumb-portsmouth.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/logo/thumb-portsmouth-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/logo/thumb-portsmouth-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>

                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/logo/portsmouth.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>Portsmouth Double Glazing | Logo</p>
                        <h6>Logo Design</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 items creatives">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/creatives/thumb-adayforward.png"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/creatives/thumb-adayforward-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/creatives/thumb-adayforward-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>

                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/creatives/adayforward.png"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>adayforward | creatives</p>
                        <h6>Social Media Post</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 items creatives">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/creatives/thumb-hippocyberinstitute.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/creatives/thumb-hippocyberinstitute-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/creatives/thumb-hippocyberinstitute-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>

                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/creatives/hippocyberinstitute.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>hippocyberinstitute | creatives</p>
                        <h6>Flyer Design</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 items creatives">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/creatives/thumb-rodidust.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/creatives/thumb-rodidust-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/creatives/thumb-rodidust-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>

                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/creatives/rodidust.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>Rodidust | creatives</p>
                        <h6>Social Media Post</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 items creatives">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/creatives/thumb-img.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/creatives/thumb-img-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/creatives/thumb-img-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>

                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/creatives/img.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>Techiedom | creatives</p>
                        <h6>Social Media Post</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 items creatives">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/creatives/thumb-img-2.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/creatives/thumb-img-2-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/creatives/thumb-img-2-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>

                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/creatives/img-2.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>Techiedom | creatives</p>
                        <h6>Social Media Post</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 items logos">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/logo/thumb-andamanbeachparadise.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/logo/thumb-andamanbeachparadise-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/logo/thumb-andamanbeachparadise-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>

                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/logo/andamanbeachparadise.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>andamanbeachparadise | Logo</p>
                        <h6>Logo Design</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 items creatives">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/creatives/thumb-img-9.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/creatives/thumb-img-9-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/creatives/thumb-img-9-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>

                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/creatives/img-9.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>M.G Mould | creatives</p>
                        <h6>Social Media Post</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 items creatives">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/creatives/thumb-img-5.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/creatives/thumb-img-5-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/creatives/thumb-img-5-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>

                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/creatives/img-5.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>Techiedom | creatives</p>
                        <h6>Social Media Post</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 items creatives">
                  <div className="item-img">
                    <picture>
                      <source
                        media="(min-width: 1200px)"
                        srcset="assets/img/graphic/creatives/thumb-starimaging.jpg"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="assets/img/graphic/creatives/thumb-starimaging-small.jpg"
                      />
                      <img
                        src="assets/img/graphic/creatives/thumb-starimaging-small.jpg"
                        alt="Ajay Rawat Web Designer"
                      />
                    </picture>

                    <div className="item-img-overlay">
                      <a
                        href="assets/img/graphic/creatives/starimaging.jpg"
                        className="popimg"
                      >
                        <span className="icon pe-7s-plus"></span>
                      </a>
                      <div className="overlay-info full-width">
                        <p>starimaging | creatives</p>
                        <h6>Social Media Post</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 text-center">
              <a
                href="https://www.behance.net/ajayrawat"
                target="_blank"
                rel="noopener noreferrer"
                className="butn butn-bg mt-50"
              >
                <span>View More</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
