import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link className="logo" to="/" data-scroll-nav="0">
            <img src="assets/img/logo.png" alt="Ajay Rawat" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar">
              <i className="fas fa-bars"></i>
            </span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link active" to="/" data-scroll-nav="0">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/" data-scroll-nav="1">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/" data-scroll-nav="2">
                  Web Projects
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/" data-scroll-nav="3">
                  Graphic Projects
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/" data-scroll-nav="4">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
